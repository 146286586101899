// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
  font-family: 'Segoe UI';
  src:
    url('/fonts/segoe_ui.woff2') format('woff2'),
    url('/fonts/segoe_ui.woff') format('woff'),
    url('/fonts/segoe_ui.ttf') format('truetype');
}

@font-face {
  font-family: 'Segoe Print';
  src:
    url('/fonts/segoe_print.woff2') format('woff2'),
    url('/fonts/segoe_print.woff') format('woff'),
    url('/fonts/segoe_print.ttf') format('truetype');
}

@import 'variables';
@import 'mixins';
@import 'breakpoints';

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Segoe UI', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body {
  height: 100%;
}

body {
  display: inline-block;
  background-color: $black-950;
  color: $black-50;
  margin: 0;
  padding: 0;
  outline: none;
  overflow-x: hidden;
  width: 100vw;
  position: relative;
}

.container,
.container-fluid,
.container-xxs,
.container-xs,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

@each $breakpoint, $value in $grid-breakpoints {
  @media (min-width: $value) {
    .container,
    .container-#{$breakpoint} {
      max-width: map-get($container-max-widths, $breakpoint);
    }
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

button,
a {
  cursor: pointer;
  border: none;
}

ul {
  list-style: none;
}

img {
  user-select: none;
  pointer-events: none;
}

/* Global Styles */

@mixin flexCss($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@each $align-name, $align-value in $flex-align-values {
  @each $justify-name, $justify-value in $flex-justify-values {
    .flex-#{$align-name}-#{$justify-name} {
      @include flexCss($align-value, $justify-value);
    }
  }
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.w-max {
  width: max-content;
}

.h-100 {
  height: 100%;
}

.min-h-full {
  min-height: 100vh;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

@each $size, $value in $gap-sizes {
  .gap-#{$size} {
    gap: $value;
  }
}
